import React from 'react';
import SsoLinks from '../sso_node/sso_links.js';
import {Link} from 'react-router-dom';
import {recaptcha} from '../commons.js';
import NewIcoPrey from '../thewatch/new-ui/components/svg/newIcoPrey.js';
import ButtonNewUI from '../thewatch/new-ui/components/micro-components/button.js';
import {isProduction, isStaging} from '../thewatch/helpers/env.js';
import {checkConnection} from '../thewatch/helpers/sso.js';

const initialState = {
  flashes: [],
  twoStep: false,
  smsRecovery: false,
  smsWaitTime: null,
  smsLimitReached: false,
  smsWaitForNew: false,
  redirect: false,
  path: '',
  email: '',
  password: '',
  error: null,
  errors: {},
  loading_get: true,
  loading: false,
  recoveryCode: false,
  ssoNode: false,
  showContent: true,
  resend: false
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    if (!recaptcha.requestFromIosClient()) {
      recaptcha.mount(this.sendForm, true);
    }

    return this.getLoginInfo();
  }

  errorsFor = (namespace) => {
    let errors;
    if ((errors = this.state.errors[namespace])) {
      return <p className="inline-errors critical" dangerouslySetInnerHTML={{__html: errors.join(', ')}} />;
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (!navigator.onLine) {
      checkConnection();
      return;
    }

    if (this.state.path === '/login') {
      if (recaptcha.requestFromIosClient()) {
        this.sendForm();
      } else {
        grecaptcha.execute();
      }
    } else {
      this.sendForm();
    }
  };

  sendForm = (token) => {
    let data = $(this.refs.form).serializeArray();
    if (token !== '') {
      data.push({name: 'captcha', value: token});
    }

    return $.ajax({
      url: '/session',
      method: 'POST',
      dataType: 'json',
      data: data,
      beforeSend: (xhr) => {
        this.setState({loading: true});
        xhr.setRequestHeader('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').getAttribute('content'));
      },
      success: (data) => {
        let obj = {
          path: data.path
        };

        if (data.flash) {
          obj['flashes'] = data.flash;
        }
        if (data.two_step) {
          obj['twoStep'] = data.two_step;
        }
        if (data.sms_recovery) {
          obj['smsRecovery'] = data.sms_recovery;
        }
        if (data.saml) {
          location.href = data.path;
        }
        if (data.redirect) {
          obj['redirect'] = data.redirect;
        }
        obj['loading'] = false;

        const formOnPasswordStep = this.state.password?.length > 0;
        const loginFailed = data.redirect !== true;
        if (grecaptcha && loginFailed && formOnPasswordStep) {
          grecaptcha.reset();
        }

        return this.setState(obj, () => {
          if (data.path === '/login') {
            return this.props.handleSuccess(data, 500);
          }
          this.setState(
            {
              showContent: false
            },
            () => {
              this.props.handleSuccess(data, 500);
            }
          );
        });
      },
      error: (err) => {
        let json_errors;
        if ((json_errors = err.responseJSON)) {
          return this.setState({
            errors: json_errors.errors,
            state: json_errors.errors,
            flashes: json_errors.flash,
            loading: false
          });
        }
      }
    });
  };

  changeAccount = (event) => {
    event.preventDefault();
    this.setState(initialState);
    return this.getLoginInfo();
  };

  displayTwoStepCode = (event) => {
    event.preventDefault();
    return this.setState(
      {
        recoveryCode: false
      },
      () => this.refs.two_step_input.focus()
    );
  };

  displayRecoveryCode = (event) => {
    event.preventDefault();
    return this.setState(
      {
        recoveryCode: true
      },
      () => this.refs.two_step_input.focus()
    );
  };

  getLoginInfo = () => {
    return $.ajax({
      cache: false,
      url: location.pathname,
      dataType: 'json',
      success: (data) => {
        return this.setState({
          ssoNode: data.sso_node,
          flashes: data.flash,
          loading_get: false
        });
      }
    });
  };

  smsNextRequestIn = () => {
    if (!this.state.smsWaitForNew) {
      return;
    }

    let timer = this.state.smsWaitForNew;
    let seconds = 0;
    let interval = setInterval(() => {
      seconds = parseInt(timer % 60, 10);

      this.setState({
        smsWaitTime: I18n.t('messages.sessions.two_step_sms_in_seconds', {count: seconds})
      });

      if (--timer < 0) {
        this.setState({
          smsWaitTime: null
        });
        clearInterval(interval);
      }
    }, 1000);
  };

  requestSmsRecovery = (event) => {
    event.preventDefault();
    $.ajax({
      url: '/request_sms_code',
      method: 'POST',
      dataType: 'json',
      data: {
        email: document.getElementById('email').value
      },
      beforeSend: (xhr) => {
        xhr.setRequestHeader('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').getAttribute('content'));
      },
      success: (response) => {
        this.setState(
          {
            flashes: response.flash,
            smsWaitForNew: response.wait_sms
          },
          () => {
            this.smsNextRequestIn();
          }
        );
      },
      error: (err) => {
        let json_errors;
        if ((json_errors = err.responseJSON)) {
          this.setState({
            errors: json_errors.errors,
            state: json_errors.errors,
            flashes: json_errors.flash,
            smsRecovery: true,
            smsLimitReached: true
          });
        }
      }
    });
  };

  handleEmailInput = (e) => {
    this.setState({email: e.target.value});
  };

  handlePasswordInput = (e) => {
    this.setState({password: e.target.value});
  };

  disableLoginButton = () => {
    if (this.state.loading) {
      return true;
    } else if (this.state.path === '') {
      return !this.state.email;
    } else if (this.state.path === '/login') {
      return this.state.password?.length === 0;
    } else {
      return true;
    }
  };

  goToMSP = () => {
    let url;
    if (isProduction()) {
      url = 'https://msp.preyproject.com';
    } else if (isStaging()) {
      url = 'https://msp.preyhq.com';
    } else {
      url = 'http://localhost:4000';
    }
    window.location.href = url;
  };

  render() {
    return !this.state.showContent ? (
      <div>
        {this.state.flashes.map((o, i) => {
          return (
            <p key={i} className={`login-newui__alert login-newui__alert--info ${o[0]} information`}>
              {o[1]}
            </p>
          );
        })}
      </div>
    ) : (
      <div className="session-container">
        <div className="top-icon">
          <figure>
            <NewIcoPrey />
          </figure>
        </div>

        <div className="flex-column gap-24">
          <h1 className="login-newui__h1">
            {this.state.ssoNode ? I18n.t('node_config.configuring_title') : I18n.t('login.login')}
          </h1>
          {this.state.ssoNode && <span>{I18n.t('node_config.login_text')}</span>}
        </div>

        <form
          autoComplete="off"
          ref="form"
          className="form form-session"
          id="new-session"
          action="/session"
          acceptCharset="UTF-8"
          onSubmit={this.handleSubmit}
        >
          <input name="utf8" type="hidden" value="✓" />
          {!this.state.loading_get && this.state.ssoNode && (
            <input name="sso_token" type="hidden" value={this.props.sso_token} />
          )}
          <div className="g-recaptcha" id="recaptcha"></div>

          {this.state.flashes.map((o, i) => {
            return (
              <p
                key={i}
                className={`login-newui__alert login-newui__alert--warning ${o[0]} information`}
                dangerouslySetInnerHTML={{__html: o[1]}}
              />
            );
          })}
          <ul className="sign-form">
            {this.state.path !== '/' && <li className="sep"></li>}

            {this.state.path === '' && (
              <li className="sep">
                <label className="login-newui__label" htmlFor="email">
                  {I18n.t('login.email')}
                </label>
                <input
                  type="email"
                  ref="email_input"
                  name="email"
                  id="email"
                  tabIndex="1"
                  className="login-newui__input"
                  autoFocus="autoFocus"
                  onChange={this.handleEmailInput}
                />
                <small>{this.errorsFor('email')}</small>
              </li>
            )}

            {this.state.path === '/login' && (
              <div>
                <input type="hidden" name="email" id="email" ref="email" value={this.state.email} />
                <li className="sep">
                  <label className="login-newui__label" htmlFor="password">
                    {I18n.t('login.password')} <b>{document.getElementById('email').value}</b>
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    tabIndex="1"
                    className="login-newui__input"
                    autoFocus="autoFocus"
                    onChange={this.handlePasswordInput}
                  />
                  {this.errorsFor('password')}
                </li>

                {this.state.twoStep &&
                  (this.state.recoveryCode ? (
                    <li className="sep" style={{marginBottom: '15px'}}>
                      <label className="login-newui__label" htmlFor="recovery_code">
                        {I18n.t('login.recovery_code')}
                      </label>
                      <input
                        type="text"
                        name="recovery_code"
                        id="recovery_code"
                        ref="two_step_input"
                        tabIndex="2"
                        className="login-newui__input"
                        maxLength="16"
                        placeholder={I18n.t('otp.placeholder_1')}
                      />
                      {this.errorsFor('recovery_code')}
                    </li>
                  ) : (
                    <li className="sep" style={{marginBottom: '15px'}}>
                      <label className="login-newui__label" htmlFor="otp_code">
                        {I18n.t('login.two_step')}
                      </label>
                      <input
                        type="text"
                        name="otp_code"
                        id="otp_code"
                        ref="two_step_input"
                        tabIndex="2"
                        className="login-newui__input"
                        maxLength="6"
                        placeholder={I18n.t('otp.placeholder_2')}
                      />
                      {this.errorsFor('otp_code')}
                    </li>
                  ))}

                {this.state.twoStep &&
                  (this.state.recoveryCode ? (
                    <li className="sep">
                      <a className="login-newui__link" href="#" onClick={this.displayTwoStepCode}>
                        <i className="icon-alert-sign"></i>
                        &nbsp;
                        {I18n.t('otp.link_2')}
                      </a>
                    </li>
                  ) : (
                    <li className="sep">
                      <a className="login-newui__link" href="#" onClick={this.displayRecoveryCode}>
                        <i className="icon-alert-sign"></i>
                        &nbsp;
                        {I18n.t('otp.link_1')}
                      </a>
                    </li>
                  ))}

                {this.state.twoStep &&
                  this.state.recoveryCode &&
                  this.state.smsRecovery &&
                  !this.state.smsLimitReached && (
                    <li className="sep">
                      {this.state.smsWaitTime ? (
                        <p className="btn-block">
                          <i className="icon-alert-sign"></i>
                          &nbsp;
                          {this.state.smsWaitTime}
                        </p>
                      ) : (
                        <a className="login-newui__link" href="#" onClick={this.requestSmsRecovery}>
                          <i className="icon-alert-sign"></i>
                          &nbsp;
                          {I18n.t('otp.link_3')}
                        </a>
                      )}
                    </li>
                  )}
              </div>
            )}

            {this.state.path !== '/' && (
              <div id="loginButtons">
                {this.state.path === '/login' && (
                  <a href={null} onClick={this.changeAccount} className="login-newui__secondary-button">
                    {I18n.t('login.change_account')}
                  </a>
                )}
                <button className="login-newui__button" disabled={this.disableLoginButton()}>
                  {this.state.loading ? (
                    <div className="loader-container-btn">
                      <i className="loader small"></i>
                      <span>{I18n.t('login.submit')}</span>
                    </div>
                  ) : (
                    I18n.t('login.submit')
                  )}
                </button>
              </div>
            )}
          </ul>
        </form>

        {!this.state.loading_get &&
          (this.state.ssoNode ? (
            <SsoLinks forgot={true} sso_token={this.props.sso_token} />
          ) : (
            <div className="login-newui__link-container">
              <ButtonNewUI
                type="secondary"
                content={I18n.t('login.msp_login')}
                action={() => this.goToMSP()}
                additionalClass="login-newui__msp-button"
              />
              <p className="sep">
                <Link className="login-newui__link" to="/forgot">
                  {I18n.t('forgot.question')}
                </Link>
              </p>

              <p className="sep">
                <Link className="login-newui__link" to="/signup">
                  {I18n.t('signup.question')}
                </Link>
              </p>

              <div className="login-newui__activation-code-container">
                <p>
                  <Link className="login-newui__link" to="/resend">
                    {' '}
                    {I18n.t('messages.signup.resend.text')}
                  </Link>
                </p>
              </div>
            </div>
          ))}
      </div>
    );
  }
}

export default Login;
