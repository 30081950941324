import React from 'react';
import NewIcoAbout from '../thewatch/new-ui/components/svg/newIcoAbout';

export default class CookieNotice extends React.Component {
  state = {
    status: {},
    loading: true
  };

  componentDidMount() {
    return $.ajax({
      url: '/policies/user_terms/cookie.json',
      success: (data) => {
        return this.setState({
          status: data.status,
          loading: false
        });
      }
    });
  }

  agreeCookies = (event) => {
    event.preventDefault();

    return $.ajax({
      url: '/policies/user_terms/cookie/accept.json',
      method: 'put',
      beforeSend: (xhr) => {
        this.setState({
          loading: true
        });
        xhr.setRequestHeader('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').getAttribute('content'));
      },
      success: (data) => {
        return this.setState(
          {
            status: data
          },
          () => {
            if (this.props.reload) {
              return location.reload();
            } else {
              return this.injectTags();
            }
          }
        );
      }
    });
  };

  injectTags = () => {
    if (window.hotjar_script) {
      return $(this.clearTag(window.hotjar_script)).appendTo('head');
    }
  };

  clearTag = (tag) => {
    return tag
      .replace(/&quot;/g, '"')
      .slice(1)
      .slice(0, -1);
  };

  render() {
    return !this.state.loading && this.state.status !== 'accepted' ? (
      <div className="login-newui__cookie-notice">
        <div className="login-newui__cookie-elements">
          <div className="login-newui__cookie-icon">
            <i>
              <NewIcoAbout color="#ffffff" />
            </i>
            <h3
              className="login-newui__cookie-h3"
              dangerouslySetInnerHTML={{
                __html: I18n.t('cookies.desc')
              }}
            />
          </div>

          <button onClick={this.agreeCookies} className="login-newui__cookie-button">
            {I18n.t('cookies.agree')}
          </button>
        </div>

        {this.props.reload && (
          <div className="mobile">
            <p>
              {I18n.t('cookies.desc_short')}
              <button href="#" className="login-newui__cookie-button" onClick={this.agreeCookies}>
                {I18n.t('cookies.agree')}
              </button>
            </p>
          </div>
        )}
      </div>
    ) : (
      <div />
    );
  }
}
