import React from 'react';

class Resend extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      errors: {},
      flashes: []
    };
  }

  errorsFor = (namespace) => {
    let errors;
    if ((errors = this.state.errors[namespace])) {
      return <p className="inline-errors critical">{errors.join(', ')}</p>;
    }
  };

  updateEmail = (event) => {
    event.preventDefault();

    this.setState({
      email: event.target.value
    });
  };

  emailEntered = () => {
    return this.state.email !== '';
  };

  handleSubmit = (event) => {
    event.preventDefault();

    return $.ajax({
      url: '/resend_activation_email',
      method: 'POST',
      dataType: 'json',
      data: $(event.currentTarget).serializeArray(),
      beforeSend: (xhr) => {
        this.setState({
          loading: true
        });
        if (this.refs.email_input) {
          this.setState({
            email: this.refs.email_input.value
          });
        }
        xhr.setRequestHeader('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').getAttribute('content'));
      },
      success: (data) => {
        this.props.history.push(`${data.path}`);
        this.setState({
          flashes: data.flash
        });
      },
      error: (err) => {
        this.props.history.push(`/`);
      }
    });
  };

  render() {
    return (
      <div>
        <div>
          {this.state.flashes.map((o, i) => {
            return (
              <p key={i} className={`alert alert-fixed ${o[0]} information`}>
                {o[1]}
              </p>
            );
          })}
        </div>
        <h1 className="login-newui__h1">{I18n.t('login.resend')}</h1>
        <form
          autoComplete="off"
          ref="form"
          className="resend-form__container"
          action="/resend_activation_email"
          acceptCharset="UTF-8"
          method="post"
          onSubmit={this.handleSubmit}
        >
          <ul className="sign-form">
            <li className="sep">
              <label className="login-newui__label" htmlFor="user_email">
                {' '}
                {I18n.t('signup.email')}{' '}
              </label>
              <input
                tabIndex="2"
                type="email"
                className="login-newui__input"
                size="30"
                name="user[email]"
                id="user_email"
                defaultValue={''}
                onChange={this.updateEmail}
              />
              {this.errorsFor('email')}
            </li>

            <li className="sep">
              <input
                type="submit"
                name="commit"
                value={I18n.t('messages.signup.resend.btn')}
                tabIndex="7"
                disabled={!this.emailEntered()}
                className={this.emailEntered() ? 'login-newui__button' : 'login-newui__button--disabled'}
                style={this.emailEntered() ? {} : {cursor: 'default'}}
              />
            </li>
          </ul>
        </form>
      </div>
    );
  }
}

export default Resend;
